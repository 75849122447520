import React from 'react';
import { Container, Navbar, Image, Card } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import HeaderWidget from './widgets/HeaderWidget';

function Home() {
  const cardStyle = {
    cursor: 'pointer',
    transition: 'background-color 0.3s',
  };

  const handleCardClick = (url) => {
    window.open(url, "_self"); 
  };

  return (
    <Container fluid>
      <Container className="mt-4">
        <HeaderWidget title={"Home"}/>

        <Card
          className="mt-4"
          style={{ ...cardStyle, backgroundColor: 'white' }}
          onClick={() => handleCardClick('/yard')}
        >
          <Card.Body>
            <h3 className="text-center">Yard</h3>
          </Card.Body>
        </Card>

        <Card
          className="mt-4"
          style={{ ...cardStyle, backgroundColor: 'white' }}
          onClick={() => handleCardClick('/yard')}
        >
          <Card.Body>
            <h3 className="text-center">PDI</h3>
          </Card.Body>
        </Card>

        <Card
          className="mt-4"
          style={{ ...cardStyle, backgroundColor: 'white' }}
          onClick={() => handleCardClick('https://ghportal.azurewebsites.net/')}
        >
          <Card.Body>
            <h3 className="text-center">Greenhouse Fleet Portal</h3>
          </Card.Body>
        </Card>

        <Card
          className="mt-4"
          style={{ ...cardStyle, backgroundColor: 'white' }}
          onClick={() => handleCardClick('/construction')}
        >
          <Card.Body>
            <h3 className="text-center">Hold Bay</h3>
          </Card.Body>
        </Card>
      </Container>

      {/* Footer */}
      <footer className="mt-4">
        <Container>{/* Your footer content here */}</Container>
      </footer>
    </Container>
  );
}

export default Home;
