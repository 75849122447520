import React, { useState } from 'react';
import { Container, Image, Card, Form, Button, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { validateCarRegistration, validateFuelAmount } from '../../util/VehicleValidator';
import HeaderWidget from '../../widgets/HeaderWidget';


function YardFuel() {

  // State for form inputs
  const [carRegistration, setCarRegistration] = useState('');
  const [fuelAmount, setFuelAmount] = useState('');
  const [fuelType, setFuelType] = useState('Petrol'); 

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    const isCarRegistrationValid = validateCarRegistration(carRegistration);
    const isFuelAmountValid = validateFuelAmount(fuelAmount);

    if (isCarRegistrationValid && isFuelAmountValid) {
      const formData = {
        carRegistration,
        fuelAmount: parseFloat(fuelAmount),
        fuelType,
      };

      try {
        const response = await fetch('http://localhost:3001/yard/fuel', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        });

        if (response.status === 201) {
          console.log('Fuel entry added successfully.');
          setCarRegistration('');
          setFuelAmount('');
          setFuelType('Petrol');
        } else {
          console.error('Failed to add fuel entry.');
        }
      } catch (error) {
        console.error('An error occurred:', error);
      }
    } else {
      console.error('Validation failed.');
    }
  };

  return (
    <Container fluid>
      <Container className="mt-4">
        <HeaderWidget title={"Fuel"}/>

        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="carRegistration">
            <Form.Label>Car Registration (UK Format)</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter car registration"
              value={carRegistration}
              onChange={(e) => setCarRegistration(e.target.value)}
              required
            />
          </Form.Group>

          <Form.Group controlId="fuelAmount">
            <Form.Label>Fuel Amount (Litres)</Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter fuel amount in litres"
              value={fuelAmount}
              onChange={(e) => setFuelAmount(e.target.value)}
              required
            />
          </Form.Group>

          <Form.Group controlId="fuelType">
            <Form.Label>Fuel Type</Form.Label>
            <Form.Control
              as="select"
              value={fuelType}
              onChange={(e) => setFuelType(e.target.value)}
              required
            >
              <option value="Petrol">Petrol</option>
              <option value="Diesel">Diesel</option>
            </Form.Control>
          </Form.Group>

          <Button type="submit" variant="primary" block>
            Submit
          </Button>
        </Form>
      </Container>

    </Container>
  );
}

export default YardFuel;
