
 // Validation functions
 const validateCarRegistration = (value) => {
    const regex = /^[A-Z]{2}\d{2}\s[A-Z]{3}$/;
    return regex.test(value);
  };

  const validateOdometerReading = (value) => {
    const odometerValue = parseFloat(value);
    return !isNaN(odometerValue) && odometerValue >= 0;
  };

  const validateFuelAmount = (value) => {
    const fuelAmountValue = parseFloat(value);
    return !isNaN(fuelAmountValue) && fuelAmountValue >= 0;
  };

  export { validateCarRegistration, validateOdometerReading, validateFuelAmount };