import React, { useState } from 'react';
import { Container, Image, Card, Form, Button, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { validateCarRegistration } from '../../util/VehicleValidator';
import HeaderWidget from '../../widgets/HeaderWidget';
function ERACOutbound() {

  // State for form inputs
  const [carRegistration, setCarRegistration] = useState('');
  const [dispatchDate, setDispatchDate] = useState('');
  const [locationCode, setLocationCode] = useState('HE'); // Default value: 'HE'

  const validateDispatchDate = (value) => {
    // Implement your validation logic for dispatch date here
    // For example, check if it's a valid date format
    return true; // Replace with your validation result
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate the inputs
    const isCarRegistrationValid = validateCarRegistration(carRegistration);
    const isDispatchDateValid = validateDispatchDate(dispatchDate);

    if (isCarRegistrationValid && isDispatchDateValid) {
      // Handle the form submission logic here
      console.log('Car Registration:', carRegistration);
      console.log('Dispatch Date:', dispatchDate);
      console.log('Location Code:', locationCode);

      // Clear the form inputs
      setCarRegistration('');
      setDispatchDate('');
      setLocationCode('HE'); // Reset the Location Code to 'HE'
    } else {
      // Handle validation errors or display a message to the user
      console.error('Validation failed.');
    }
  };

  return (
    <Container fluid>
      <Container className="mt-4">
       <HeaderWidget title={"ERAC Outbound"}/>

        {/* Form */}
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="carRegistration">
            <Form.Label>Car Registration (UK Format)</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter car registration"
              value={carRegistration}
              onChange={(e) => setCarRegistration(e.target.value)}
              required
            />
          </Form.Group>

          <Form.Group controlId="dispatchDate">
            <Form.Label>Dispatch Date</Form.Label>
            <Form.Control
              type="date"
              placeholder="Enter dispatch date"
              value={dispatchDate}
              onChange={(e) => setDispatchDate(e.target.value)}
              required
            />
          </Form.Group>

          <Form.Group controlId="locationCode">
            <Form.Label>Location Code</Form.Label>
            <Form.Control
              as="select"
              value={locationCode}
              onChange={(e) => setLocationCode(e.target.value)}
              required
            >
              <option value="HE">HE</option>
              {/* Add more location codes if needed */}
            </Form.Control>
          </Form.Group>

          <Button type="submit" variant="primary" block>
            Submit
          </Button>
        </Form>
        {/* End Form */}

        {/* Existing Card Components */}
        {/* ... (Your existing Card components here) */}
      </Container>

      {/* Footer */}
      <footer className="mt-4">
        <Container>{/* Your footer content here */}</Container>
      </footer>
    </Container>
  );
}

export default ERACOutbound;
