import React, { useEffect } from 'react';
import { Container, Image } from 'react-bootstrap';

function HeaderWidget({ title }) {

    useEffect(() => {
        document.title = title;
      }, []);

  return (
    <Container fluid>
      <Container className="mt-4">
        <div className="text-center">
          <Image
            src="https://henstridgepdi.online/gallery_gen/c198c5a2de981df13e550c3a52ed67e9_380x380_fit.png"
            roundedCircle
            style={{ width: '100px', height: '100px' }}
            alt="Circular Image"
          />
        </div>
        <h1 className="text-center">{title}</h1>
      </Container>
    </Container>
  );
}

export default HeaderWidget;
