import React from 'react';
import { Container, Image, Card } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import HeaderWidget from '../../widgets/HeaderWidget';

function Yard() {
  const cardStyle = {
    cursor: 'pointer',
    transition: 'background-color 0.3s',
  };

  const handleCardClick = (url) => {
    window.open(url, '_self'); 
  };

  return (
    <Container fluid>
      <Container className="mt-4">
      <HeaderWidget title={"Yard"}/>


        <Card
          className="mt-4"
          style={{ ...cardStyle, backgroundColor: 'white' }}
          onClick={() => handleCardClick('/yard/erac-inbound')}
        >
          <Card.Body>
            <h3 className="text-center">ERAC Inbound</h3>
          </Card.Body>
        </Card>

        <Card
          className="mt-4"
          style={{ ...cardStyle, backgroundColor: 'white' }}
          onClick={() => handleCardClick('/yard/erac-outbound')}
        >
          <Card.Body>
            <h3 className="text-center">ERAC Outbound</h3>
          </Card.Body>
        </Card>

        <Card
          className="mt-4"
          style={{ ...cardStyle, backgroundColor: 'white' }}
          onClick={() => handleCardClick('/construction')}
        >
          <Card.Body>
            <h3 className="text-center">Fuel</h3>
          </Card.Body>
        </Card>

        <Card
          className="mt-4"
          style={{ ...cardStyle, backgroundColor: 'white' }}
          onClick={() => handleCardClick('/construction')}
        >
          <Card.Body>
            <h3 className="text-center">Pre-notified</h3>
          </Card.Body>
        </Card>
      </Container>
    </Container>
  );
}

export default Yard;
