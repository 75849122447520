import React, { useState } from 'react';
import { Container, Image, Card, Form, Button, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import HeaderWidget from '../../widgets/HeaderWidget';
import { validateOdometerReading, validateCarRegistration } from '../../util/VehicleValidator';

function ERACInbound() {

  // State for form inputs
  const [carRegistration, setCarRegistration] = useState('');
  const [odometerReading, setOdometerReading] = useState('');


  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate the inputs
    const isCarRegistrationValid = validateCarRegistration(carRegistration);
    const isOdometerReadingValid = validateOdometerReading(odometerReading);

    if (isCarRegistrationValid && isOdometerReadingValid) {
      // Handle the form submission logic here
      console.log('Car Registration:', carRegistration);
      console.log('Odometer Reading:', odometerReading);

      // Clear the form inputs
      setCarRegistration('');
      setOdometerReading('');
    } else {
      // Handle validation errors or display a message to the user
      console.error('Validation failed.');
    }
  };

  return (
    <Container fluid>
      <Container className="mt-4">
      <HeaderWidget title={"ERAC Inbound"}/>

        {/* Form */}
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="carRegistration">
            <Form.Label>Car Registration (UK Format)</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter car registration"
              value={carRegistration}
              onChange={(e) => setCarRegistration(e.target.value)}
              required
            />
          </Form.Group>

          <Form.Group controlId="odometerReading">
            <Form.Label>Odometer Reading</Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter odometer reading"
              value={odometerReading}
              onChange={(e) => setOdometerReading(e.target.value)}
              required
            />
          </Form.Group>

          <Button type="submit" variant="primary" block>
            Submit
          </Button>
        </Form>
        {/* End Form */}

        {/* Existing Card Components */}
        {/* ... (Your existing Card components here) */}
      </Container>

      {/* Footer */}
      <footer className="mt-4">
        <Container>{/* Your footer content here */}</Container>
      </footer>
    </Container>
  );
}

export default ERACInbound;
