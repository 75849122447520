import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Yard from "./pages/yard/Yard";
import Home from "./Home";
import ERACInbound from "./pages/yard/ERACInboundForm";
import ERACOutbound from "./pages/yard/ERACOutboundForm";
import YardFuel from "./pages/yard/YardFuel";
import UnderConstruction from "./UnderConstruction";

const root = ReactDOM.createRoot(
  document.getElementById("root")
);

root.render(
  <BrowserRouter>
  <Routes>
  <Route path="/construction" element={<UnderConstruction />} />
  <Route path="yard/fuel" element={<YardFuel />} />
  <Route path="yard/erac-inbound" element={<ERACInbound />} />
  <Route path="yard/erac-outbound" element={<ERACOutbound />} />
  <Route path="yard" element={<Yard />} />
    <Route path="/" element={<Home />}>
  </Route>
  </Routes>
</BrowserRouter>
);